// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #f4a11e;
  --ion-color-primary-rgb: 244,161,30;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #d78e1a;
  --ion-color-primary-tint: #f5aa35;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** main **/
  --ion-color-main: #4D81AA;
  --ion-color-main-rgb: 77,129,170;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255,255,255;
  --ion-color-main-shade: #447296;
  --ion-color-main-tint: #5f8eb3;

  /** orange **/
  --ion-color-orange: #FD8008;
  --ion-color-orange-rgb: 253,128,8;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 0,0,0;
  --ion-color-orange-shade: #df7107;
  --ion-color-orange-tint: #fd8d21;

  /** inactive **/
  --ion-color-inactive: #68737D;
  --ion-color-inactive-rgb: 104,115,125;
  --ion-color-inactive-contrast: #ffffff;
  --ion-color-inactive-contrast-rgb: 255,255,255;
  --ion-color-inactive-shade: #5c656e;
  --ion-color-inactive-tint: #77818a;

  /** purple **/
  --ion-color-purple: #80007F;
  --ion-color-purple-rgb: 128,0,127;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255,255,255;
  --ion-color-purple-shade: #710070;
  --ion-color-purple-tint: #8d1a8c;

  /** green **/
  --ion-color-green: #15A302;
  --ion-color-green-rgb: 21,163,2;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #128f02;
  --ion-color-green-tint: #2cac1b;

  /** yellow **/
  --ion-color-yellow: #FFFF0A;
  --ion-color-yellow-rgb: 255,255,10;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0,0,0;
  --ion-color-yellow-shade: #e0e009;
  --ion-color-yellow-tint: #ffff23;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** slide **/
  --ion-color-slide: #389ACA;
  --ion-color-slide-rgb: 56,154,202;
  --ion-color-slide-contrast: #ffffff;
  --ion-color-slide-contrast-rgb: 0,0,0;
  --ion-color-slide-shade: #3188b2;
  --ion-color-slide-tint: #4ca4cf;

  /** slidec **/
  --ion-color-slidec: #819AD7;
  --ion-color-slidec-rgb: 129,154,215;
  --ion-color-slidec-contrast: #ffffff;
  --ion-color-slidec-contrast-rgb: 0,0,0;
  --ion-color-slidec-shade: #7288bd;
  --ion-color-slidec-tint: #8ea4db;

  --ion-grid-columns: 50;
}

.ion-color-main {
  --ion-color-base: #4D81AA;
  --ion-color-base-rgb: 77,129,170;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #447296;
  --ion-color-tint: #5f8eb3;
}

.ion-color-orange {
  --ion-color-base: #FD8008;
  --ion-color-base-rgb: 253,128,8;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #df7107;
  --ion-color-tint: #fd8d21;
}

.ion-color-inactive {
  --ion-color-base: #68737D;
  --ion-color-base-rgb: 104,115,125;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #5c656e;
  --ion-color-tint: #77818a;
}

.ion-color-purple {
  --ion-color-base: #80007F;
  --ion-color-base-rgb: 128,0,127;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #710070;
  --ion-color-tint: #8d1a8c;
}

.ion-color-green {
  --ion-color-base: #15A302;
  --ion-color-base-rgb: 21,163,2;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #128f02;
  --ion-color-tint: #2cac1b;
}

.ion-color-yellow {
  --ion-color-base: #FFFF0A;
  --ion-color-base-rgb: 255,255,10;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #e0e009;
  --ion-color-tint: #ffff23;
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255,255,255;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #e0e0e0;
  --ion-color-tint: #ffffff;
}

.ion-color-slide {
  --ion-color-base: #389ACA;
  --ion-color-base-rgb: 56,154,202;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #3188b2;
  --ion-color-tint: #4ca4cf;
}

.ion-color-slidec {
  --ion-color-base: #819AD7;
  --ion-color-base-rgb: 129,154,215;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #7288bd;
  --ion-color-tint: #8ea4db;
}

/*
 * Custom Styling
 * ----------------------------------------------------------------------------
 */
 ion-popover{
  .popover-wrapper{
    .popover-content{
      width:80vw;
    }
  }
 }

@media (prefers-color-scheme: disabled) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}