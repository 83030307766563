/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/themes/dark.scss";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";


//Align
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.align-right {
  display: flex;
  align-items: right;
  justify-content: right;
  text-align: right;
}

.align-left {
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
}

//Chip
.chip {
  /* Center the content */
  align-items: center;
  display: inline-flex;
  justify-content: center;

  /* Background color */
  background-color: rgba(0, 0, 0, 0.1);

  /* Rounded border */
  border-radius: 9999px;

  /* Spacing */
  padding: 4px 8px;

  color: black;
  font-weight: bold;
  font-size: smaller;
  margin: 4px;
}

ion-grid {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-modal.full-width {
  --width: 100%;
  --border-radius: 0;
}

ion-modal.full-height {
  --height: 100%;
  --border-radius: 0;
}

//Modal
.modal-boomin {
  --min-width: 100%;
  --min-height: 50%;
}

.modal-checklist {
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 90%;
  --min-width: 85%;
  --width: auto !important;

  ion-backdrop {
    --backdrop-opacity: 0.6;
  }

  --height: 90%;
}

.modal-checkpoint {
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 350px;
  --min-width: 50%;
  --height: auto !important;
  --width: auto !important;

  ion-backdrop {
    --backdrop-opacity: 0.6;
  }

  // --height: 29vh;
}

.modal-contact {
  --min-height: 300px;
  --height: auto !important;

  ion-backdrop {
    --backdrop-opacity: 0.6;
  }
}

.modal-guard {
  --min-height: 350px;
  --height: auto !important;

  ion-backdrop {
    --backdrop-opacity: 0.6;
  }
}

.modal-password {
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 20vh;
  --min-width: 40%;
  --width: auto !important;

  ion-backdrop {
    --backdrop-opacity: 0.6;
  }

  --height: 40vh;
}

.incident-checkbox-icons ion-icon {
  font-size: 24px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.header-device {
  background-color: var(--ion-color-dark);
  border-right: 0px;
  border-bottom: 0px;
  padding: 0%;
  margin: 0%;

  ion-row {
    border-right: 0px;
    border-bottom: 0px;
    padding: 0%;
    margin: 0%;
  }

  ion-col {
    border-right: 0px;
    border-bottom: 0px;
    padding: 0%;
    margin: 0%;
  }
}

//Center
.center-horizontal {
  display: flex;
  justify-content: center;
}

.center-vertical {
  display: flex;
  align-items: center;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  ion-icon {
    vertical-align: middle;
    padding-bottom: 0.3vh;
  }
}

//Popover
.popover-custom {
  --backdrop-opacity: 0.6;
  --background: rgba(255, 255, 255, 0.8);
  --max-width: 95%;
  --min-width: 15vw;
  --width: auto !important;
  --max-height: 95%;

  .popover-content {
    max-width: 95%;
    width: auto !important;
  }
}

//Color Sequencer
.color-sequencer:nth-child(5n + 1) ion-icon {
  color: rgb(95, 15, 64);
}

.color-sequencer:nth-child(5n + 2) ion-icon {
  color: rgb(254, 180, 123);
}

.color-sequencer:nth-child(5n + 3) ion-icon {
  color: rgb(255, 126, 95);
}

.color-sequencer:nth-child(5n + 4) ion-icon {
  color: rgb(118, 82, 133);
}

.color-sequencer:nth-child(5n + 5) ion-icon {
  color: rgb(53, 28, 77);
}

.color-sequencer:nth-child(5n + 6) ion-icon {
  color: rgb(50, 72, 92);
}

.color-sequencer:nth-child(5n + 7) ion-icon {
  color: rgb(19, 85, 137);
}

.color-sequencer:nth-child(5n + 8) ion-icon {
  color: rgb(42, 147, 213);
}

.color-sequencer:nth-child(5n + 9) ion-icon {
  color: rgb(174, 217, 218);
}

.color-sequencer:nth-child(5n + 10) ion-icon {
  color: rgb(132, 153, 116);
}

.color-sequencer:nth-child(5n + 11) ion-icon {
  color: rgb(181, 231, 160);
}

.color-sequencer:nth-child(5n + 12) ion-icon {
  color: rgb(130, 183, 75);
}

.color-sequencer:nth-child(5n + 13) ion-icon {
  color: rgb(0, 255, 102);
}

.color-sequencer:nth-child(5n + 14) ion-icon {
  color: rgb(255, 238, 173);
}

.color-sequencer:nth-child(5n + 15) ion-icon {
  color: rgb(255, 204, 92);
}

.color-sequencer:nth-child(5n + 16) ion-icon {
  color: rgb(255, 123, 37);
}

.color-sequencer:nth-child(5n + 17) ion-icon {
  color: rgb(255, 111, 105);
}

.color-sequencer:nth-child(5n + 1) ion-toggle {
  --background-checked: rgba(95, 15, 64, 0.5);
  --handle-background-checked: rgb(95, 15, 64);
}

.color-sequencer:nth-child(5n + 2) ion-toggle {
  --background-checked: rgba(254, 180, 123, 0.5);
  --handle-background-checked: rgb(254, 180, 123);
}

.color-sequencer:nth-child(5n + 3) ion-toggle {
  --background-checked: rgba(255, 126, 95, 0.5);
  --handle-background-checked: rgb(255, 126, 95);
}

.color-sequencer:nth-child(5n + 4) ion-toggle {
  --background-checked: rgba(118, 82, 133, 0.5);
  --handle-background-checked: rgb(118, 82, 133);
}

.color-sequencer:nth-child(5n + 5) ion-toggle {
  --background-checked: rgba(53, 28, 77, 0.5);
  --handle-background-checked: rgb(53, 28, 77);
}

.color-sequencer:nth-child(5n + 6) ion-toggle {
  --background-checked: rgba(50, 72, 92, 0.5);
  --handle-background-checked: rgb(50, 72, 92);
}

.color-sequencer:nth-child(5n + 7) ion-toggle {
  --background-checked: rgba(19, 85, 137, 0.5);
  --handle-background-checked: rgb(19, 85, 137);
}

.color-sequencer:nth-child(5n + 8) ion-toggle {
  --background-checked: rgba(42, 147, 213, 0.5);
  --handle-background-checked: rgb(42, 147, 213);
}

.color-sequencer:nth-child(5n + 9) ion-toggle {
  --background-checked: rgba(174, 217, 218, 0.5);
  --handle-background-checked: rgb(174, 217, 218);
}

.color-sequencer:nth-child(5n + 10) ion-toggle {
  --background-checked: rgba(132, 153, 116, 0.5);
  --handle-background-checked: rgb(132, 153, 116);
}

.color-sequencer:nth-child(5n + 11) ion-toggle {
  --background-checked: rgba(181, 231, 160, 0.5);
  --handle-background-checked: rgb(181, 231, 160);
}

.color-sequencer:nth-child(5n + 12) ion-toggle {
  --background-checked: rgba(130, 183, 75, 0.5);
  --handle-background-checked: rgb(130, 183, 75);
}

.color-sequencer:nth-child(5n + 13) ion-toggle {
  --background-checked: rgba(0, 255, 102, 0.5);
  --handle-background-checked: rgb(0, 255, 102);
}

.color-sequencer:nth-child(5n + 14) ion-toggle {
  --background-checked: rgba(255, 238, 173, 0.5);
  --handle-background-checked: rgb(255, 238, 173);
}

.color-sequencer:nth-child(5n + 15) ion-toggle {
  --background-checked: rgba(255, 204, 92, 0.5);
  --handle-background-checked: rgb(255, 204, 92);
}

.color-sequencer:nth-child(5n + 16) ion-toggle {
  --background-checked: rgba(255, 123, 37, 0.5);
  --handle-background-checked: rgb(255, 123, 37);
}

.color-sequencer:nth-child(5n + 17) ion-toggle {
  --background-checked: rgba(255, 111, 105, 0.5);
  --handle-background-checked: rgb(255, 111, 105);
}

.popover-color-sequencer:nth-child(5n + 1) ion-icon {
  color: rgb(42, 147, 213);
}

.popover-color-sequencer:nth-child(5n + 2) ion-icon {
  color: rgb(174, 217, 218);
}

.popover-color-sequencer:nth-child(5n + 3) ion-icon {
  color: rgb(132, 153, 116);
}

.popover-color-sequencer:nth-child(5n + 4) ion-icon {
  color: rgb(181, 231, 160);
}

.popover-color-sequencer:nth-child(5n + 5) ion-icon {
  color: rgb(130, 183, 75);
}

.popover-color-sequencer:nth-child(5n + 6) ion-icon {
  color: rgb(0, 255, 102);
}

.popover-color-sequencer:nth-child(5n + 7) ion-icon {
  color: rgb(255, 238, 173);
}

.popover-color-sequencer:nth-child(5n + 8) ion-icon {
  color: rgb(255, 204, 92);
}

.popover-color-sequencer:nth-child(5n + 9) ion-icon {
  color: rgb(255, 123, 37);
}

.popover-color-sequencer:nth-child(5n + 10) ion-icon {
  color: rgb(255, 111, 105);
}

.popover-color-sequencer:nth-child(5n + 11) ion-icon {
  color: rgb(95, 15, 64);
}

.popover-color-sequencer:nth-child(5n + 12) ion-icon {
  color: rgb(254, 180, 123);
}

.popover-color-sequencer:nth-child(5n + 13) ion-icon {
  color: rgb(255, 126, 95);
}

.popover-color-sequencer:nth-child(5n + 14) ion-icon {
  color: rgb(118, 82, 133);
}

.popover-color-sequencer:nth-child(5n + 15) ion-icon {
  color: rgb(53, 28, 77);
}

.popover-color-sequencer:nth-child(5n + 16) ion-icon {
  color: rgb(50, 72, 92);
}

.popover-color-sequencer:nth-child(5n + 17) ion-icon {
  color: rgb(19, 85, 137);
}

//Message
.message-error p {
  color: #f53d3d;
  padding-left: 0.8vw;
}

.message-success p {
  color: var(--ion-color-success);
  padding-left: 0.8vw;
}

//Spinner
.custom-loader-class {
  --backdrop-opacity: 0.8;
  --background: none;
  --spinner-color: var(--ion-color-primary);

  ion-spinner {
    transform: scale(5);
  }
}

.loading-wrapper.sc-ion-loading-md {
  -webkit-box-shadow: 0 16px 20px rgba(0, 0, 0, 0);
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0);
}

//Webkit
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: -webkit-gradient(linear, left top, left bottom, from(#fd8008), to(#ff8a00));
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  background: linear-gradient(to right, #201c29, #201c29 1px, #100e17 1px, #100e17);
}

ion-segment.global-segment {
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-dark);
  // border-bottom: 3px solid var(--ion-color-dark);
  justify-content: start;
  width: auto;
  overflow-x: auto;

  ion-segment-button {
    height: 30;
    min-height: 30;
    --color: var(--ion-color-dark);
    --color-checked: var(--ion-color-orange);
    --color-hover: var(--ion-color-light);
    --background-checked: var(--ion-color-light);
  }
}

ion-searchbar b {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

ion-card {
  border: solid black 1px;
}

ion-card-content {
  border-top: solid black 1px;
}

.border {
  border: solid black 1px;
}

ion-item-divider {
  border: solid black 1px;
}

ion-progress-bar {
  --background: black;
  // --progress-background: var(--ion-color-purple);
  --progress-background: #28ba62;
}

//Wrap text
.wrap-text {
  white-space: normal !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.overflowX {
  justify-content: start;
  width: auto;
  overflow-x: auto;
  padding-top: 1px;
}

.underline {
  text-decoration: underline;
}